import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-fallo-login-microsoft',
  templateUrl: './fallo-login-microsoft.component.html',
  styleUrls: ['./fallo-login-microsoft.component.scss']
})
export class FalloLoginMicrosoftComponent implements OnInit {


    constructor( public dialogRef: MatDialogRef<FalloLoginMicrosoftComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public data: any, )
         { dialogRef.disableClose = true; }

     public ngOnInit(): void {

      }

    public onOkClick(): void {

        this.dialogRef.close('ok');
      }

}
